/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
import * as React from 'react'
import Paper from '@material-ui/core/Paper'
// import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { Tooltip, Typography as Text } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconPicker from '../../apps/AppComponents/IconPicker/IconPicker'
// import IconButton from '@material-ui/core/IconButton'
// import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import styles from './WidgetStyles'
import { logDashboardEvent } from '../events'

/**
 * Reusable Widget Body
 */
// eslint-disable-next-line no-unused-vars
export function Header({ title, category }) {
  const classes = styles()
  if (title === 'My Profile') {
    return null
  }
  // TODO: Add back ability to remove widget from dashboard
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Text variant="h1" className={classes.header}>
            {title}
          </Text>
        </Grid>

        <Grid item>
          <Grid container direction="column">
            {/* <Grid item>
              {category === 'default' ? null : (
                <IconButton className={classes.removeWidgetIcon}>
                  <HighlightOffIcon className={classes.removeWidgetHighlight} />
                </IconButton>
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <Divider classes={{ root: classes.divider }} />
    </>
  )
}

export function Content({
  children,
  className = null,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const targetRef = React.useRef()
  const [height, setHeight] = React.useState({
    height:
      showNote && notePosition && noteMessage && noteMessage !== '' ? 435 : 475,
  })
  React.useEffect(() => {
    if (
      targetRef.current !== null &&
      showNote &&
      noteMessage &&
      noteMessage !== '' &&
      notePosition
    ) {
      setHeight({
        height: noteMessage ? 475 - targetRef.current.offsetHeight : 475,
      })
    }
  }, [])

  const classes = styles()
  if ((notePosition === 'bottom' || notePosition === 'middle') && showNote) {
    // Note position is bottom or middle
    return (
      <div
        style={{
          position: 'relative',
          width: 335,
        }}
      >
        <div
          className={[classes.content, className].join(' ')}
          style={{ height: height.height }}
        >
          {children}
        </div>
        <div
          style={{
            backgroundColor: noteBackground,
            position: 'relative',
            fontSize: '1.2em',
            textAlign: 'center',
            width: '100%',
            height: 'fit-content',
            borderRadius: '5px',
          }}
        >
          <div className={classes.linkContainer} ref={targetRef}>
            {noteIcon ? (
              <IconPicker iconName={noteIcon} size="lg" color={noteFontColor} />
            ) : null}
            <Link href={noteUrl} target="_blank">
              <Typography
                style={{
                  color: noteFontColor,
                }}
                variant="subtitle1"
              >
                {noteMessage}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    )
  } else if (notePosition === 'top' && showNote) {
    // Note position is top
    return (
      <div
        style={{
          position: 'relative',
          width: 335,
        }}
      >
        <div
          style={{
            backgroundColor: noteBackground,
            position: 'relative',
            fontSize: '1.2em',
            textAlign: 'center',
            width: '100%',
            height: 'fit-content',
            borderRadius: '5px',
          }}
        >
          <div className={classes.linkContainer} ref={targetRef}>
            <IconPicker iconName={noteIcon} size="lg" color={noteFontColor} />
            <Link href={noteUrl} target="_blank">
              <Typography
                style={{
                  color: noteFontColor,
                }}
                variant="subtitle1"
              >
                {noteMessage}
              </Typography>
            </Link>
          </div>
        </div>
        <div
          className={[classes.content, className].join(' ')}
          style={{ height: height.height }}
        >
          {children}
        </div>
      </div>
    )
  } else {
    // No notes
    return (
      <div
        className={[classes.content, className].join(' ')}
        style={{ height: 475 }}
      >
        {children}
      </div>
    )
  }
}

// TODO: Double check with Edwin why blurry was removed

export function Spacer() {
  const classes = styles()
  return <div className={classes.spacer} />
}

export function RowContainer({ children, className = null }) {
  const classes = styles()
  return (
    <div className={[classes.rowContainer, className].join(' ')}>
      {children}
    </div>
  )
}

export function Row({ children, className }) {
  const classes = styles()
  return (
    <ButtonBase className={[classes.row, className].join(' ')}>
      {children}
    </ButtonBase>
  )
}

export function TouchableRow({ children, className, onClick }) {
  const classes = styles()
  return (
    <ButtonBase
      className={[classes.row, className].join(' ')}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  )
}

export function SmallIconLink({
  icon,
  iconLabel,
  classes,
  link,
  widgetId,
  dispatch,
}) {
  const handleEventClick = () => {
    window.open(link, '_blank')
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        iconLabel.props.children,
        link,
      )
    }
  }
  return (
    <ButtonBase
      onClick={() => handleEventClick()}
      className={classes.linkContainer}
    >
      <IconPicker iconName={icon} size="lg" color="white" />
      <Text className={classes.tinyText}>{iconLabel}</Text>
    </ButtonBase>
  )
}

export function TabLink({
  icon,
  iconLabel,
  hyperLink,
  disabled = false,
  widgetId,
  dispatch,
}) {
  const classes = styles()
  const [open, setOpen] = React.useState(false)

  const isOverflowing = (e) =>
    e.offsetWidth < e.scrollWidth || e.offsetHeight < e.scrollHeight

  const handleMouseEnter = (event) => {
    if (disabled) {
      return
    }
    const element = event.target
    if (isOverflowing(element)) {
      setOpen(true)
    }
  }

  const handleMouseLeave = () => {
    if (disabled) {
      return
    }
    setOpen(false)
  }

  const handleEventClick = () => {
    window.open(hyperLink, '_blank')
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        iconLabel,
        hyperLink,
      )
    }
  }

  const handlePress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      window.open(hyperLink, '_blank')
    }
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        iconLabel,
        hyperLink,
      )
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      className={classes.tabLinkBox}
    >
      <Grid
        container
        tabIndex={0}
        onClick={handleEventClick}
        onKeyPress={handlePress}
        className={classes.profileDeepLink}
      >
        <Grid item xs={12}>
          {icon}
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={classes.textCenter}
          >
            <Tooltip
              title={iconLabel}
              enterDelay={500}
              onOpen={handleMouseEnter}
              onClose={handleMouseLeave}
              enterNextDelay={250}
              open={open}
            >
              <Text
                variant="body2"
                id="tab-link-label"
                component="p"
                color="textPrimary"
                className={classes.tabLinkText}
              >
                {iconLabel}
              </Text>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
  // return (
  //   <Box
  //     display="flex"
  //     justifyContent="space-between"
  //     alignItems="center"
  //     flexDirection="column"
  //     className={classes.tabLinkBox}
  //   >
  //     <Grid
  //       tabIndex={0}
  //       className={classes.profileDeepLink}
  //       onClick={handleEventClick}
  //     >
  //       {icon}
  //     </Grid>
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       flexDirection="column"
  //       className={classes.textCenter}
  //     >
  //       <Tooltip
  //         title={iconLabel}
  //         enterDelay={500}
  //         onOpen={handleMouseEnter}
  //         onClose={handleMouseLeave}
  //         enterNextDelay={250}
  //         open={open}
  //       >
  //         <Text
  //           variant="body2"
  //           id="tab-link-label"
  //           component="p"
  //           color="textPrimary"
  //           className={classes.tabLinkText}
  //         >
  //           {iconLabel}
  //         </Text>
  //       </Tooltip>
  //     </Box>
  //   </Box>
  // )
}

function Widget({ children, className = null }) {
  const classes = styles()
  return (
    <Paper className={[classes.widget, className].join(' ')} elevation={0}>
      {children}
    </Paper>
  )
}

Widget.Header = Header
Widget.Content = Content
Widget.Row = Row
Widget.TouchableRow = TouchableRow
Widget.RowContainer = RowContainer
Widget.Spacer = Spacer
Widget.TabLink = TabLink
Widget.SmallIconLink = SmallIconLink

export default Widget
